<template>
  <div>
    <iframe
      :src="`https://player.vimeo.com/video/${vimeoId}?autoplay=${
        autoplay ? '1' : '0'
      }&h=${vimeoH}`"
      frameborder="0"
      allow="autoplay; fullscreen"
      class="vimeo-player"
      allowfullscreen
      ref="vimeo"
    >
    </iframe>
  </div>
</template>

<script>
import Player from "@vimeo/player";

export default {
  name: "VimeoPlayer",
  props: {
    vimeoId: [String, Number],
    vimeoH: [String, Number],
    autoplay: {
      type: Boolean,
      default: true,
    },
    pauseTrigger: Number,
  },
  data() {
    return {
      vimeoPlayer: null,
    };
  },
  mounted() {
    this.vimeoPlayer = new Player(this.$refs.vimeo);
  },
  watch: {
    pauseTrigger() {
      this.vimeoPlayer.pause();
    },
  },
};
</script>

<style scoped lang="scss">
.vimeo-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
