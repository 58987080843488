<template>
  <div
    class="aggregate-rating"
    :class="{ empty: count == 0, 'aggregate-rating--light': light }"
  >
    <svg
      width="112"
      height="18"
      viewBox="0 0 112 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      v-if="value"
    >
      <path
        class="aggregate-rating__svg-star"
        d="M18.3678 6.30259L12.9665 5.52699L10.552 0.69055C10.486 0.558131 10.3775 0.450935 10.2435 0.385776C9.90738 0.221829 9.49893 0.358452 9.33087 0.69055L6.91635 5.52699L1.51505 6.30259C1.36613 6.32361 1.22999 6.39297 1.12575 6.49807C0.999726 6.62604 0.930284 6.79822 0.932677 6.97675C0.93507 7.15529 1.0091 7.32559 1.13851 7.45022L5.04642 11.2147L4.12316 16.5304C4.10151 16.654 4.11536 16.7812 4.16314 16.8975C4.21091 17.0138 4.29071 17.1145 4.39348 17.1882C4.49624 17.262 4.61786 17.3058 4.74455 17.3147C4.87124 17.3236 4.99793 17.2973 5.11024 17.2387L9.94142 14.7291L14.7726 17.2387C14.9045 17.3081 15.0577 17.3312 15.2044 17.306C15.5746 17.2429 15.8235 16.8961 15.7597 16.5304L14.8364 11.2147L18.7443 7.45022C18.8507 7.34723 18.9209 7.21271 18.9422 7.06558C18.9996 6.69774 18.7401 6.35724 18.3678 6.30259Z"
        v-for="i in 5"
        :key="`path--${i}--${value}`"
        :fill="starFill(i)"
        :transform="`translate(${(i - 1) * 23} 0)`"
        stroke="black"
      />
      <defs>
        <linearGradient
          :key="`starPortion--${value}`"
          :id="`starPortion--${value}`"
        >
          <stop offset="0%" stop-color="black" />
          <stop :offset="starModuloPercent" stop-color="black" />
          <stop :offset="starModuloPercent" stop-color="white" />
          <stop offset="100%" stop-color="white" />
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: "AggregateRating",
  props: {
    value: Number,
    count: Number,
    light: Boolean,
  },
  methods: {
    starFill(iStar) {
      if (this.value >= iStar) return "#000";
      else if (this.value + 1 >= iStar)
        return `url(#starPortion--${this.value})`;
      else return "#fff";
    },
  },
  computed: {
    starModulo() {
      return this.value % 1.0;
    },
    starModuloPercent() {
      return `${parseInt(this.starModulo * 100.0)}%`;
    },
  },
};
</script>
