<template>
  <div class="review-slide">
    <div
      class="review-slide__video-wrapper"
      v-if="slide.value && slide.value.vimeo_id"
    >
      <v-responsive class="review-slide__video" :aspect-ratio="480 / 280">
        <VimeoPlayer
          v-if="slide.value.vimeo_id && dialog"
          :vimeoId="slide.value.vimeo_id"
          :vimeoH="slide.value.vimeo_h"
          :autoplay="false"
          :pauseTrigger="`${pauseTrigger}--${dialog}`"
        />
      </v-responsive>
    </div>
    <div
      class="review-slide__image"
      v-else-if="slide.value && slide.value.preview"
      :style="{ backgroundImage: `url(${slide.value.preview.large.url})` }"
    ></div>
    <div
      class="review-slide__pdf"
      v-else-if="
        slide.value &&
        slide.value.type === 'file' &&
        slide.value.url.endsWith('pdf')
      "
    >
      <pdfjs-viewer-element
        :src="slide.value.url"
        viewer-path="/pdfjs-3.11.174-dist"
      ></pdfjs-viewer-element>
    </div>
  </div>
</template>

<script>
import "pdfjs-viewer-element";

export default {
  props: {
    slide: Object,
    dialog: Boolean,
    pauseTrigger: Number,
  },
  mounted() {
    console.info("slide slide", this.slide);
  },
};
</script>

<style lang="scss" scoped>
.review-slide {
  height: 100%;
  padding-left: 92px;
  padding-right: 92px;
  &__image,
  &__video-wrapper,
  &__pdf {
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: contain;
  }
  &__video-wrapper {
    display: flex;
  }
  &__pdf pdfjs-viewer-element {
    height: 100%;
  }
}
</style>
